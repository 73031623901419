import React from "react";

const InfoBoxContent = ({ point, callback }) => {
  return (
    <div
      style={{
        position: "relative",
        width: "200px",
        minHeight: "100px",
        margin: "16px",
        padding: "17px",
        color: "#000",
        background: "#fff",
        borderRadius: "10px",
        borderBottom: "7px solid #F15A22",
        boxShadow: "0 7px 15px -6px #132528",
        zIndex: "1000",
        userSelect: "none",
      }}
    >
      <button
        type="button"
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          padding: "7px",
          fontFamily: "'ZonaPro', sans-serif",
          fontSize: "16px",
          lineHeight: "0.5",
          fontWeight: "bold",
          color: "#F15A22",
        }}
        onClick={() => callback()}
        onKeyPress={(e) => {
          if (e.key === "enter") callback();
        }}
      >
        x
      </button>
      <div
        style={{
          fontSize: "18px",
          fontFamily: "'ZonaPro', sans-serif",
          color: "#132528",
        }}
      >
        {point.name}
      </div>
      <hr
        style={{
          height: "2px",
          margin: "10px 0",
          backgroundColor: "#c4c4c4",
        }}
      />
      <div
        style={{
          fontSize: "16px",
          fontFamily: "'ZonaPro', sans-serif",
          color: "#132528",
        }}
      >
        {point.address}
      </div>
      <hr
        style={{
          height: "2px",
          margin: "10px 0",
          backgroundColor: "#c4c4c4",
        }}
      />
      <div
        style={{
          fontSize: "14px",
          fontFamily: "'ZonaPro', sans-serif",
          color: "#132528",
        }}
      >
        {point.charger}
      </div>
      <div
        style={{
          width: "20px",
          height: "12px",
          background: "#F15A22",
          clipPath: "polygon(0 0, 50% 100%, 100% 0)",
          position: "absolute",
          top: "calc(100% + 6px)",
          left: "calc(50% - 10px)",
        }}
      />
    </div>
  );
};

export default InfoBoxContent;
