import React, { useState, useEffect, useCallback } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import {
  GoogleMap,
  Marker,
  StandaloneSearchBox,
  MarkerClusterer,
  InfoBox,
  DirectionsRenderer,
} from "@react-google-maps/api";

import { Badge, Button, Form } from "react-bootstrap";

import InfoBoxContent from "./InfoBoxContent";
import { mapStyles, clusterStyles } from "../../../utils/google-map-styles";
import { isBrowser } from "../../../helpers/checkEnvironment";

import {
  Lightning,
  PointCircle,
  PointPin,
  Search,
} from "../../../helpers/Icons";

import "react-perfect-scrollbar/dist/css/styles.css";

const Map = ({ data }) => {
  if (!data) return null;

  const {
    map,
    ui_directions_title: uiDirectionsTitle,
    ui_directions_from: uiDirectionsFrom,
    ui_directions_to: uiDirectionsTo,
    ui_search: uiSearch,
    ui_clear: uiClear,
    ui_open: uiOpen,
    ui_close: uiClose,
  } = data;

  if (!map) return null;

  // Add ids to all points in the map
  const mapWithIds = map.map((mapPoint, index) => {
    const point = mapPoint;
    const coord = point.coordinates.latitude - point.coordinates.longitude;
    point.id = `${point.name.replace(/[^A-Za-z0-9]/g, "")}${coord}-${index}`;
    return point;
  });

  const [points] = useState(mapWithIds);
  const [zoom] = useState(2);
  const [center] = useState({ lat: 37.98381, lng: 23.727539 });
  const [bounds, setBounds] = useState(null);
  const [infoBox, setInfoBox] = useState(null);
  const [showWidgets, setShowWidgets] = useState(false);
  const [directionsFrom, setDirectionsFrom] = useState(null);
  const [directionsTo, setDirectionsTo] = useState(null);
  const [directionsRoute, setDirectionsRoute] = useState(null);

  const [mapRef, setMapRef] = useState(null);
  const [searchBox, setSearchBox] = useState(null);
  const [fromInput, setFromInput] = useState(null);
  const [toInput, setToInput] = useState(null);

  // Utility to handle classlist
  const setClass = (el, action, target, cls) => {
    if (action === "add") return el.closest(target).classList.add(cls);
    if (action === "remove") return el.closest(target).classList.remove(cls);
    return null;
  };

  // Map loaded
  const onMapLoad = useCallback(
    (_map) => {
      setMapRef(_map);

      if (window.matchMedia("(min-width: 1025px)").matches) {
        setShowWidgets(true);
      }

      const allPointsBounds = new window.google.maps.LatLngBounds();

      points.map((item) => {
        allPointsBounds.extend({
          lat: item.coordinates.latitude,
          lng: item.coordinates.longitude,
        });
        return item.id;
      });

      _map.fitBounds(allPointsBounds);
      setBounds(allPointsBounds);
    },
    [points]
  );

  // The function for calculating the cluster icon image.
  // https://github.com/gmaps-marker-clusterer/gmaps-marker-clusterer/blob/c9d448d62b5634db4e76b6b7e3d913703cbed303/src/markerclusterer.js#L458
  const clusterCalculator = (clusterMarkers, numStyles) => {
    let index = 0;
    const count = clusterMarkers.length;
    let dv = count;

    while (dv !== 0) {
      dv = parseInt(dv / 10, 10);
      index += 1;
    }

    index = Math.min(index, numStyles);

    let chargerCount = 0;
    clusterMarkers.forEach((marker) => {
      const markerCount = Number(marker.title.split("x")[0]);
      chargerCount += Number.isNaN(markerCount) ? 1 : markerCount;
    });

    return {
      text: chargerCount,
      index,
    };
  };

  // Get the closest point from the list
  const getClosestPoint = useCallback(
    (point, list = points) => {
      return list.reduce((previous, current) => {
        const currentDistance =
          window.google.maps.geometry.spherical.computeDistanceBetween(point, {
            lat: current.coordinates.latitude,
            lng: current.coordinates.longitude,
          });
        const previousDistance =
          window.google.maps.geometry.spherical.computeDistanceBetween(point, {
            lat: previous.coordinates.latitude,
            lng: previous.coordinates.longitude,
          });

        return currentDistance < previousDistance ? current : previous;
      });
    },
    [points]
  );

  const getMultipleClosestPoints = useCallback(
    (point, count = 2) => {
      let places = points;
      const closestPoints = [];

      Array.from({ length: count }).forEach(() => {
        places = places.filter(
          (place) =>
            !closestPoints.find((closePoint) => closePoint.id === place.id)
        );

        closestPoints.push(getClosestPoint(point, places));
      });

      return closestPoints;
    },
    [getClosestPoint, points]
  );

  // Search box places changed
  const placesChanged = () => {
    const search = searchBox;
    let changedBounds = bounds;
    let boundsPadding = 150;

    if (search.getPlaces()?.length > 0) {
      const searchPlace = search.getPlaces()[0];

      const closestPoint = getClosestPoint({
        lat: searchPlace.geometry.location.lat(),
        lng: searchPlace.geometry.location.lng(),
      });

      changedBounds = searchPlace.geometry.viewport;
      changedBounds.extend({
        lat: closestPoint.coordinates.latitude,
        lng: closestPoint.coordinates.longitude,
      });
    }

    if (window.matchMedia("(max-width: 1024px)").matches) {
      setShowWidgets(false);
      boundsPadding = 100;
    }
    mapRef.fitBounds(changedBounds, boundsPadding);
  };

  // Handle click on points list item
  const onWidgetPointClick = (point) => {
    setInfoBox(point);
    mapRef.setZoom(15);
    mapRef.panTo({
      lat: point.coordinates.latitude,
      lng: point.coordinates.longitude,
    });

    if (window.matchMedia("(max-width: 1024px)").matches) {
      setShowWidgets(false);
    }
  };

  // Get route based on directions inputs
  useEffect(() => {
    if (!directionsFrom || !directionsTo) {
      setDirectionsRoute(null);

      if (mapRef?.current && bounds) {
        mapRef.fitBounds(bounds);
      }

      return null;
    }

    const DirectionsService = new window.google.maps.DirectionsService();

    DirectionsService.route(
      {
        origin: { placeId: directionsFrom.place_id },
        destination: { placeId: directionsTo.place_id },
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status !== window.google.maps.DirectionsStatus.OK)
          // eslint-disable-next-line no-alert
          return alert("Something went wrong. Please try again.");

        setInfoBox(null);
        setDirectionsRoute(result);

        return null;
      }
    );

    return null;
  }, [bounds, directionsFrom, directionsTo, mapRef]);

  // Extend map bounds on directions route change
  useEffect(() => {
    if (
      !directionsRoute ||
      (directionsRoute.routes.length < 1 && directionsRoute.routes[0].legs < 1)
    )
      return null;

    const closestPlaces = [];
    const route = directionsRoute.routes[0];
    const leg = directionsRoute.routes[0].legs[0];

    closestPlaces.push(...getMultipleClosestPoints(leg.start_location));
    closestPlaces.push(...getMultipleClosestPoints(leg.end_location));

    closestPlaces.forEach((place) => {
      route.bounds.extend({
        lat: place.coordinates.latitude,
        lng: place.coordinates.longitude,
      });
    });

    mapRef.fitBounds(route.bounds);

    return null;
  }, [directionsRoute, getMultipleClosestPoints, mapRef]);

  // Reset the directions related components
  const resetDirections = () => {
    setDirectionsFrom(null);
    setDirectionsTo(null);
    setDirectionsRoute(null);
    mapRef.fitBounds(bounds);

    if (isBrowser) {
      const from = document.getElementById("mapRoute.from");
      const to = document.getElementById("mapRoute.to");

      from.value = "";
      setClass(from, "add", ".widget-input", "is-empty");
      to.value = "";
      setClass(to, "add", ".widget-input", "is-empty");
    }
  };

  return (
    <div className="charge-points-map__container">
      <aside
        className="map-widgets"
        data-show={showWidgets}
        id="mapWidgets"
        tabIndex="-1"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(showWidgets
          ? { "aria-modal": "true", role: "modal" }
          : { "aria-hidden": "true" })}
      >
        <div className="map-widgets__content">
          <button
            type="button"
            className="map-widgets__toggle-wrapper"
            aria-controls="mapWidgets"
            onClick={() => setShowWidgets(!showWidgets)}
          >
            <div className="map-widgets__toggle">
              <div>{showWidgets ? uiClose : uiOpen}</div>
            </div>
          </button>
          <section className="map-widget map-widget--route">
            <h4 className="mb-3">{uiDirectionsTitle}</h4>
            <div
              className="widget-input widget-input--floating text-primary mb-4 is-empty"
              data-empty={!!directionsFrom}
            >
              <PointCircle />
              <StandaloneSearchBox
                className="widget-input--box"
                onLoad={(ref) => setFromInput(ref)}
                onPlacesChanged={() =>
                  setDirectionsFrom(fromInput.getPlaces()[0])
                }
              >
                <Form.Control
                  type="text"
                  id="mapRoute.from"
                  placeholder={uiDirectionsFrom}
                  className="text-primary bg-white border-0 rounded-pill"
                  onFocus={(e) =>
                    setClass(e.target, "add", ".widget-input", "is-focused")
                  }
                  onBlur={(e) =>
                    setClass(e.target, "remove", ".widget-input", "is-focused")
                  }
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setClass(e.target, "add", ".widget-input", "is-empty");
                      setDirectionsFrom(null);
                    } else {
                      setClass(e.target, "remove", ".widget-input", "is-empty");
                    }
                  }}
                />
              </StandaloneSearchBox>
              <Form.Label htmlFor="mapRoute.from">
                {uiDirectionsFrom}
              </Form.Label>
            </div>
            <div className="widget-input widget-input--floating text-primary is-empty">
              <PointPin />
              <StandaloneSearchBox
                onLoad={(ref) => setToInput(ref)}
                onPlacesChanged={() => setDirectionsTo(toInput.getPlaces()[0])}
              >
                <Form.Control
                  type="text"
                  id="mapRoute.to"
                  placeholder={uiDirectionsTo}
                  className="text-primary bg-white border-0 rounded-pill"
                  onFocus={(e) =>
                    setClass(e.target, "add", ".widget-input", "is-focused")
                  }
                  onBlur={(e) =>
                    setClass(e.target, "remove", ".widget-input", "is-focused")
                  }
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setClass(e.target, "add", ".widget-input", "is-empty");
                      setDirectionsTo(null);
                    } else {
                      setClass(e.target, "remove", ".widget-input", "is-empty");
                    }
                  }}
                />
              </StandaloneSearchBox>
              <Form.Label htmlFor="mapRoute.to">{uiDirectionsTo}</Form.Label>
            </div>
            {(directionsFrom || directionsTo) && (
              <div className="mt-3 text-end">
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="rounded-pill"
                  onClick={resetDirections}
                >
                  {uiClear}
                </Button>
              </div>
            )}
          </section>
          <section className="map-widget map-widget--points">
            <div className="widget-points">
              <div className="widget-points__search">
                <div className="widget-input">
                  <Search />
                  <StandaloneSearchBox
                    onLoad={(ref) => setSearchBox(ref)}
                    onPlacesChanged={placesChanged}
                  >
                    <Form.Control
                      type="search"
                      id="mapSearch"
                      className="text-primary bg-transparent border-primary rounded-pill"
                      placeholder={uiSearch}
                    />
                  </StandaloneSearchBox>
                  <Form.Label htmlFor="mapSearch" className="visually-hidden">
                    {uiSearch}
                  </Form.Label>
                </div>
              </div>
              <div className="widget-points__list-wrapper horizontal-shadow horizontal-shadow--small horizontal-shadow--bottom">
                <PerfectScrollbar
                  onScrollY={(container) =>
                    container.parentElement.classList.add(
                      "horizontal-shadow--top",
                      "horizontal-shadow--bottom"
                    )
                  }
                  onYReachEnd={(container) =>
                    container.parentElement.classList.remove(
                      "horizontal-shadow--bottom"
                    )
                  }
                  onYReachStart={(container) =>
                    container.parentElement.classList.remove(
                      "horizontal-shadow--top"
                    )
                  }
                >
                  <ul className="widget-points__list list-unstyled">
                    {points.map((point) => (
                      <li
                        key={point.id}
                        // eslint-disable-next-line prettier/prettier
                        className={`widget-points__list-item${infoBox?.id === point.id ? " active" : ""}`}
                      >
                        <button
                          type="button"
                          className="text-start"
                          onClick={() => onWidgetPointClick(point)}
                        >
                          <span className="name d-inline-block lh-1 mb-1">
                            {point.name}
                          </span>
                          <br />
                          <span className="address d-inline-block mb-3">
                            {point.address}
                          </span>
                          <br />
                          <Badge
                            className="charger border border-primary fw-normal text-wrap"
                            bg={null}
                            text="primary"
                          >
                            <Lightning /> {point.charger}
                          </Badge>
                        </button>
                      </li>
                    ))}
                  </ul>
                </PerfectScrollbar>
              </div>
            </div>
          </section>
        </div>
      </aside>
      <GoogleMap
        onLoad={(_map) => {
          onMapLoad(_map);
        }}
        mapContainerClassName="charge-points-map__map"
        zoom={zoom}
        center={center}
        options={{ disableDefaultUI: true, styles: mapStyles }}
        onClick={() => setInfoBox(null)}
      >
        <MarkerClusterer
          averageCenter
          calculator={clusterCalculator}
          enableRetinaIcons
          minimumClusterSize={3}
          styles={clusterStyles}
          onClick={() => setInfoBox(null)}
        >
          {(clusterer) =>
            points.map((point) => {
              const { latitude, longitude } = point.coordinates;

              return (
                <Marker
                  key={point.id}
                  position={{ lat: latitude, lng: longitude }}
                  icon="/images/pin.svg"
                  animation={
                    isBrowser ? window.google.maps.Animation.DROP : null
                  }
                  onClick={() => setInfoBox(point)}
                  title={point.charger}
                  clusterer={clusterer}
                >
                  {infoBox && infoBox.id === point.id && (
                    <InfoBox
                      onCloseClick={() => setInfoBox(null)}
                      options={{
                        alignBottom: true,
                        pixelOffset: isBrowser
                          ? new window.google.maps.Size(-113, -65)
                          : null,
                        closeBoxURL: "",
                        enableEventPropagation: true,
                      }}
                    >
                      <InfoBoxContent
                        point={point}
                        callback={() => setInfoBox(null)}
                      />
                    </InfoBox>
                  )}
                </Marker>
              );
            })
          }
        </MarkerClusterer>
        {directionsRoute && <DirectionsRenderer directions={directionsRoute} />}
      </GoogleMap>
    </div>
  );
};

export default Map;
